@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

html {
  overflow-y: scroll;
}
body {
  background: #01161e;
  font-family: "Montserrat", sans-serif;

  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "ResotYg";
  src: local("ResotYg"), url("ResotYg.woff") format("woff");
}
h1,
h2 {
  font-family: "ResotYg";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.active {
  color: #598392;
}
